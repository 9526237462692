

h1,h2,h3,h4,h5,h6 {
	text-transform: uppercase;
	letter-spacing: .1rem;
}
h1,h2 {
	letter-spacing: .2rem;
	font-size: 1.5rem;
}

.button {
	font-family: $header-font-family;
	text-transform: uppercase;
	letter-spacing: .2rem;
}

hr {
	margin: 2rem 0;
	//border-color: $primary-color;
}

label { 
	//font-size: 1.0rem;
	//letter-spacing: .02rem;
	//background-color: $medium-gray;
	//color: $white;
	//padding: .25rem .5rem;
	font-family: $header-font-family; 
	color: inherit;
} 

