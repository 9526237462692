
.section {
	position: relative; 
	padding: 1rem;
}

.section_content {
	position: relative;
	z-index: 10;
}


/** 
 * - - - - - - - - - - - - - - - - - - - - -
 * Section Background Images
 * - - - - - - - - - - - - - - - - - - - - -
 */
.has_image, .has-image {
	overflow-y: hidden; 
	position: relative;
	
	.section-content {
		position: relative;
		z-index: 10;
	}

}

div.section_background_div,
div.section-background-div {
	&.opaque {
		opacity: 1;
	}
	&.fade-85 {
		opacity: .85; 
	}
	&.fade-50 {
		opacity: .5;
	}
	&.fade-30 {
		opacity: .30;
	}
	&.fade-15 {
		opacity: .15;
	}
}

.section_background_div,
.section-background-div,
.section-background-image { 
	
	position: absolute; 
	left:0px;
	right:0px;
	top: 0px;
	bottom: 0px;
	background-size: cover;
	background-position: center center;
}

img.section-background-img,
img.section-background-image,
video.section-background-image {
	opacity: 0.01;
	min-width: 100%;
	max-width: 300%;
	min-height: 100%;
	z-index: 0;
	transition: opacity 2s;
	&.loaded {
		opacity: 1;
		&.fade-85 {
			opacity: .85; 
		}
		&.fade-50 {
			opacity: .5;
		}
		&.fade-30 {
			opacity: .30;
		}
		&.fade-15 {
			opacity: .15;
		}
	}

}
.section-content {
	z-index: 20;
	position: relative;
}

//@media #{$medium-up} {

	.parallax {
		overflow: hidden;
		perspective: 10px;
		
		will-change: perspective-origin;

		.section-background-image {
			top: -100px;
			bottom: -100px;
			left: 0;
			will-change: transform; 
			min-height: 110%;
			transform: translateZ(-10px) scale(2);
			
		}
		&.deep .section-background-image {
			transform: translateZ(-40px) scale(5); 
		}
	} 

//}

.monochrome .section-background-image {
	
}



/** 
 * - - - - - - - - - - - - - - - - - - - - -
 * Section Padding
 * - - - - - - - - - - - - - - - - - - - - -
 */
.section-content {
	padding-top: 60px;
	padding-bottom: 60px;
}

.no-padding {
	padding: 0px;
}
.thin-padding {
	padding-top: 15px;
	padding-bottom: 15px;  
}
.normal-padding {
	padding-top: 60px; 
	padding-bottom: 60px;
}	
.thick-padding {
	padding-top: 120px;
	padding-bottom: 120px;
}

.mega-padding {
	padding-top: 20vh;
	padding-bottom: 20vh;
} 

.side-padding {
	padding-left: 30px;
	padding-right: 30px;
}

.full-height {
	min-height: 90vh;
	display: flex;
	align-items: center; 
	justify-content: center;
}
.no-top-padding {
	padding-top: 0px;
}
.no-bottom-padding {
	padding-bottom: 0px;
}

/** 
 * - - - - - - - - - - - - - - - - - - - - -
 * Section Sizes
 * - - - - - - - - - - - - - - - - - - - - -
 */

.section.fullscreen {
	min-height: 90vh;
}

.section.ratio {
	height: 56vw;
}

.section.fullscreen,
.section.ratio {
	display: flex;
	align-items: center;
	justify-content: center;
	.section-content {
		flex: 1;
	}
}


/** 
 * - - - - - - - - - - - - - - - - - - - - -
 * Content Width
 * - - - - - - - - - - - - - - - - - - - - -
 */

.row.half-width {
	max-width: $global-width/2;
}
.row.two-thirds,
.row.two-thirds-width {
	max-width: $global-width/3*2;
}

.fullwidth.row,
.fullwidth.section_content > .row  {
	max-width: 100%;
	//padding-left:	rem-calc(20);
	//padding-right:	rem-calc(20);	
}
.section-content.expanded > .column {
	padding: 0px;
}

.section_content.two-thirds > .column {
	@extend .medium-8;
	@extend .small-centered;
	margin: 0px auto;
}

.section_content.half > .column {
	@extend .medium-6;
	@extend .small-centered;
	margin: 0px auto;
}

