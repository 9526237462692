@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "components/sections";
@import "components/typography";

body {
	background-color: $light-gray;
	color: $dark-gray;
	
}
.bg-white {
	background: $white;
	color: $medium-gray;
}
.bg-gray {
	background-color: $light-gray;
	color: $dark-gray;
}

.button.bg-gray {
	background-color: $medium-gray; 
	color: $white;
	&:hover {
		background-color: $primary-color;
	}
}

#request {
	background-color: $warning-color;
	color: #FFF;
	font-family: $header-font-family;
	text-transform: uppercase;
	text-align: center;
	border: none;
	outline: none;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28252, 252, 252%29'></polygon></svg>")
}

#submitter-section {
	margin-bottom: 0px;
	padding-bottom: 0px;
}

#request-select {

	&>.columns {
		margin-bottom: -45px;
	}
	select {
		margin-bottom: 0px;
	}
}


